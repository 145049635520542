import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { v4 } from "uuid";
import { useDocument } from '../../Pages/SpreadsheetContainer/documentInfoContex';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from '../SkeletonLoader/Loader';
import MainLayout from '../MainLayout';
import { verifyExternal } from '../../utils/api';

const External = () => {
    const { id } = useParams();
    const history = useHistory();
    const { isVerified, setIsExternal, setUserInfo, setDocumentInfo,setIsReadOnly,setIsVerified } = useDocument();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const result = await verifyExternal(id);
                setIsExternal(true);
                setIsVerified(true);
                setUserInfo({
                    userId: `external-${v4()}`,
                    email: 'N/A',
                    name: 'External User'
                });
                setIsReadOnly(true);
                setDocumentInfo(result?.data);
            } catch (error) {
                console.error("API error:", error);
                history.push('/unauthorized');
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    return loading ? <Loader /> : isVerified ? <MainLayout /> : null;
};

export default External;
