import React from "react";
import { Avatar as AntAvatar } from "antd";
import { useDocument } from "../../Pages/SpreadsheetContainer/documentInfoContex";

const getInitials = (name) => {
    if (!name) return "?";
    const words = name.trim().split(" ");
    return words.length > 1
        ? words[0][0].toUpperCase() + words[1][0].toUpperCase()
        : words[0][0].toUpperCase();
};

const AvatarComp = ({ size = "large" }) => {
    const {  userInfo } = useDocument();
    const hasImage = userInfo?.image;
    const userInitials = getInitials(userInfo?.name);

    return (
        <AntAvatar
            size={size}
            src={hasImage ? userInfo.image : null}
            style={{
                width:"46px",
                height:"46px",
                backgroundColor: hasImage ? "transparent" : "#CF9FFF",
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 18,
                color: "white",
                fontWeight: 600,
            }}
        >
            {!hasImage && userInitials}
        </AntAvatar>
    );
};

export default AvatarComp;
