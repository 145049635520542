import React, { createContext, useContext, useState } from "react";
const DocumentContext = createContext();

export const DocumentInfoProvider = ({ children }) => {
  const [documentInfo, setDocumentInfo] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [docID, setDocID] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [savingData, setSavingData] = useState({
    isSaving: '',
    lastSavedDateTime: "",
    fileSize: 0,
  });
  const [isExternal, setIsExternal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isExternalAllow, setIsExternalAllow] = useState(false);

  return (
    <DocumentContext.Provider
      value=
      {{
        documentInfo,
        isReadOnly,
        userInfo,
        docID,
        sheets,
        savingData,
        isExternal,
        isVerified,
        isExternalAllow,
        setDocumentInfo,
        setIsReadOnly,
        setUserInfo,
        setDocID,
        setSheets,
        setSavingData,
        setIsExternal,
        setIsVerified,
        setIsExternalAllow,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = () => {
  return useContext(DocumentContext);
};
