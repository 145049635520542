import React, { useState } from "react";
import { Modal, Tooltip } from "antd";
// import "antd/dist/antd.css";
import shareModal from './ShareModal.module.css'
import { ShareModalContent } from "./ShareModalContent/ShareModalContent";
import ShareBtn from '../../assets/share-file.svg';

export const ShareModal = ({ shareUrl }) => {
  const isMobile = window.screen.width < 769;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [privacy, setPrivacy] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setPrivacy(0);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPrivacy(0);
  };

  return (
    <>
      <Tooltip title='Share document'>
        <div onClick={showModal} style={{ cursor: "pointer", zIndex: 1 }} >
          <img className={shareModal.sharebtn} src={ShareBtn} style={{ height: isMobile ? "22px" : "30px", position: "relative", top: isMobile ? '3px' : '' }} />
        </div>
      </Tooltip>
      <Modal
        title="Share with People"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className={`share-doc-modal`}
      >
        <ShareModalContent shareUrl={shareUrl} setIsModalVisible={setIsModalVisible} privacy={privacy} setPrivacy={setPrivacy} />
      </Modal>
    </>
  );
};
