import React from "react";
import { Route, Switch } from "react-router-dom";
import "./index.css";
import ResultComponent from "./components/ResultComponent/ResultComponent";
import MileGrid from "./components/Milegrid";

function App() {

  return (
    <div className="App">
      <Switch>
        <Route exact path="/milegrid/:id">
          <MileGrid isExternal={false} />
        </Route>
        <Route exact path="/milegrid/external/:id">
          <MileGrid isExternal={true} />
        </Route>
        <Route exact path="/invalid-document">
          <ResultComponent title="Document Verification Failed , Invalid Document. " />
        </Route>
        <Route path="/unauthorized">
          <ResultComponent title="Security Verification Failed , Unauthorized Access. " />
        </Route>
        <Route path="/no-internet">
          <ResultComponent title="No Internet, Check your internet connection." />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
