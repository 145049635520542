import React, { useState } from 'react'
import { AuthenticateDocument } from '../../utils/AuthenticateDocument';
import MainLayout from '../MainLayout';
import External from '../ExternalUser';

const MileGrid = ({ isExternal }) => {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    return (
        <div className='App'>
            {isExternal ? (
                <External/>
            ) : isAuthenticating ? (
                <AuthenticateDocument setIsLoading={setIsAuthenticating} />
            ) : (
                <MainLayout />
            )}
        </div>
    )
}

export default MileGrid;