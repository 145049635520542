import React from 'react';
import { useDocument } from '../../Pages/SpreadsheetContainer/documentInfoContex';
import Header from '../Header/Header';
import OfflineStatus from '../Header/OfflineStatus/OfflineStatus';
import { SpreadsheetContainerRd } from '../../Pages/SpreadsheetContainer/SpreadsheetContainerRd';
import { SpreadsheetContainer } from '../../Pages/SpreadsheetContainer/SpreadsheetContainer';

const MainLayout = () => {
    const { isReadOnly } = useDocument();
    return (
        <>
            {isReadOnly ? (
                <>
                    <Header fileDisplay={false} />
                    <OfflineStatus />
                    <SpreadsheetContainerRd />
                </>
            ) : (
                <>
                    <Header fileDisplay={true} />
                    <OfflineStatus />
                    <SpreadsheetContainer
                    />
                </>
            )}
        </>
    )
}

export default MainLayout;