// import "antd/dist/antd.css";
import classes from "./ShareModalContent.module.css";
import { useState } from "react";
import { ShareInput } from "../ShareInput/ShareInput";
import { SharePrivacy } from "../SharePrivacy/SharePrivacy";

export const ShareModalContent = ({shareUrl,setIsModalVisible,privacy,setPrivacy}) => {
  const [contentToggle, setContentTogle] = useState(false);

  return (
    <div className={classes.modalContent}>
      <ShareInput contentToggle={contentToggle} shareUrl={shareUrl} privacy={privacy}/>

      <SharePrivacy contentToggle={contentToggle} setIsModalVisible={setIsModalVisible} privacy={privacy} setPrivacy={setPrivacy} />

      {/* {contentToggle ? (
        <Button onClick={() => setContentTogle(false)}>Get Link</Button>
      ) : (
        <Button onClick={() => setContentTogle(true)}>Share With People</Button>
      )} */}
    </div>
  );
};
