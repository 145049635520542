import axios from "axios";
import { formatFileSize, getWorkWiseAuthToken } from '../utils/helper';

export const workwiseUrl = "https://workw.com/workwapi/api";

const workwiseHeaders = { 'Authorization': `Bearer ${getWorkWiseAuthToken()}` }; // workwise auth header with bearer token
let debounceTimeout;
export const workwiseServices = {

  async getDocumentById(id) {
    // Todo : To be Used When Live is Down

    //!   const dummyResponse = {
    //!    status: 200,
    //!    data: {
    //!      data: {
    //!        rightType: 2,
    //!        members: [],
    //!        name: "Dummy",
    //!        creator: {
    //!          id: "dss",
    //!          name: "YUP",
    //!          image: "tt"
    //!        },
    //!        createBy: "dsds"
    //!      }
    //!    }
    //!  }
    //!   return dummyResponse;

    return axios.get(
      `${workwiseUrl}/Authorize/Authorize?id=${id}`,
      { headers: workwiseHeaders });
  },
  async getAllSuggestion(payload) {
    return axios.post(
      `${workwiseUrl}/Suggestion/GetAllSuggestion`,
      payload,
      { headers: workwiseHeaders });
  },
  async addDocumentMember(id, payload) {
    return axios.post(
      `${workwiseUrl}/Document/AddDocumentMember?id=${id}`,
      payload,
      { headers: workwiseHeaders });
  },
  async shareDocumentToAnyone(payload) {
    return axios.post(`${workwiseUrl}/Document/UpdateDocument`,
      payload,
      { headers: workwiseHeaders }
    )
  },

  async getMailConfiguration(userId) {
    return axios.get(`${workwiseUrl}/Mailbox/GetAllUserEmailConfiguration?id=${userId}`,
      { headers: workwiseHeaders }
    )
  },

  async sendEmail(payload) {
    return axios.post(`${workwiseUrl}/Mailbox/sendEmail`,
      payload,
      { headers: workwiseHeaders }
    )
  },
  async saveDocumentlastUpdate(id, setSavingData, data) {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const size = new TextEncoder().encode(JSON.stringify(data)).length || 0;
    const sizeWithUnit = formatFileSize(size);

    if (setSavingData) {
      setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });

      await new Promise(resolve => setTimeout(resolve, 800));

      setSavingData({
        isSaving: 'Saved',
        lastSavedDateTime: formattedDate,
        fileSize: sizeWithUnit
      });
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        await axios.post(`${workwiseUrl}/Document/SaveDocumentAttachment?attachmentId=${id}&fileSize=${size}`,
          {},
          { headers: workwiseHeaders }
        );
        // Handle the response if needed
      } catch (e) {
        console.error("error", e);
      }
    }, 5000);
  },
  async completeDocument(id) {
    try {
      const formData = new FormData();
      formData.append('id', id);

      await axios.post(`${workwiseUrl}/Document/CompleteDocument?id=${id}`,
        formData,
        { headers: workwiseHeaders }
      );
      // Handle the response if needed
    } catch (e) {
      console.error("error", e);
    }
  },
  async removeDocumentMember(data) {
    await axios.post(
      `${workwiseUrl}/Document/RemoveDocumentMember?id=${data.documentId}&status=${data.status}`,
      data.documentMembers,
      { headers: workwiseHeaders }
    );
  },
};